.modal-preview {
  .value {
    font-weight: 400;
    font-size: 14;
    font-family: "Roboto";
    color: #8e8e8e;
    word-break: break-all;
    margin: 10px auto 20px auto;
  }

  .key {
    font-weight: 700;
    font-size: 12;
    font-family: "Roboto";
    color: #2c2c2c;
    min-width: fit-content;
    font-size: 20;
    font-weight: 400;
    background: #b7c1f3;
  }

  Card.card {
    background: rgb(239 239 239);
    padding: 10px;
    margin-bottom: 3%;
    border-radius: 20px;
  }

  .cardContent {
    background: rgb(239 239 239);
  }

  div.wrapperQuestion {
    text-align: center;
    margin: 10px 0px 30px 0px;
  }
}
