@keyframes rotate-wrapper {
    0%,
    100% {
      animation-timing-function: cubic-bezier(0.2 0 0.8 0.8);
    }
    50% {
      animation-timing-function: cubic-bezier(0.2 0.2 0.8 1);
    }
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes loading-animation {
    0%,
    100% {
      animation-timing-function: cubic-bezier(0.2 0 0.8 0.8);
    }
    50% {
      animation-timing-function: cubic-bezier(0.2 0.2 0.8 1);
    }
    0% {
      transform: scale(0);
      /* left: -30px; */
    }
    50% {
      transform: scale(1);
      /* left: -30px; */
    }
    100% {
      transform: scale(0);
      /* left: -30px; */
    }
  }
  
  .custom-loading-spinner {
    display: inline-block;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 0;
    transition: all 200ms ease-in-out;
    pointer-events: none;
    outline: none;
  }

  .container_spinner {
    min-width: 1000px;
    z-index: 9999;
    position: fixed;
    background: rgb(170, 169, 169);
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    box-shadow: none;
  }
  
  .loading-wrapper {
    transform: translate(100px, 82px);
    width: 36px;
    height: 100px;
    position: absolute;
    animation: rotate-wrapper 1s linear infinite;
  }
  
  .loading {
    position: absolute;
    background-color: gray;
    left: -30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #eceaea;
    animation: loading-animation 1s linear infinite;
  }
  
  .loading-2,
  .loading-wrapper-2 {
    /* background: #af1e1e;   */
    animation-delay: -0.3s;
  }
  
  .loading-3,
  .loading-wrapper-3 {
    animation-delay: -0.6s;
  }
  
  .loading-2 {
    background: #969ea0;
  }
  
  .loading-3 {
    background-color: #00a7da;
  }
  
  .loading-content {
    position: relative;
    background-color: #00a7da;
  }
  
  h3{
    font-size: 20px;
    font-weight: 700;
    margin-top: 160px;
    color: #00a7da;
  }
  
  .show-loading {
    opacity: 1;
  }
  