
div.fieldText {
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    font-size: 14px;

    fieldset {
      border-radius: 8px;
      font-size: 14px;
    }; 
}

div.dropdown {
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    font-size: 14px;

    fieldset {
      border-radius: 8px;
      font-size: 14px;
    }; 

    legend span {
        padding-left: 20px;
    }
}

.wrapper_description{

    margin-bottom: 3%;

    .description_select {
        font-size: 14px;
        text-align: start;
        color: #8E8E8E;
        word-wrap: break-word;
    }
}

div.wrapperCheckbox {
    margin-top: 5%;
}

label.labelCheckbox span.MuiTypography-root {
    color: gray
}
